(function () {
  function loadAsset(type, url) {
    return new Promise(function (resolve, reject) {
      let element;
      if (type === 'js') {
        element = document.createElement('script');
        element.src = url;
      }
      if (type === 'css') {
        element = document.createElement('link');
        element.href = url;
        element.rel = 'stylesheet';
        element.type = 'text/css';
      }
      element.addEventListener('load', function () {
        resolve(true);
      });
      element.addEventListener('error', function() {
        reject(url);
      })
      document.head.appendChild(element);
    });
  }

  const loadAssetsInOrder = function (assets) {
    const promises = [];
    const origin = document.currentScript.src.split('/').slice(0, -1).join('/')
    if (assets) {
      Object.keys(assets).forEach(function (key) {
        if (assets[key] && assets[key].length) {
          assets[key].forEach(function (url) {
            promises.push(loadAsset(key, `${origin}/${url}`));
          });
        }
      });
    }
    return Promise.all(promises);
  };


  console.log(document.currentScript);
  loadAssetsInOrder({"js":["runtime.ce0330830ee187ed91a4.bundle.js","vendor.703.7c427eb3.chunk.js","main.cc39cb89e7e360d6dd6d.bundle.js"],"css":["703.0ad4f801c3df8e452844.css","main.9febd6a4fbbe1da18685.css"]}).catch(
    (url) => { console.error('header-loader.js failed to load a header asset:', url, '\n'); }
  );
})();
